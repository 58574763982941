.settings-menu-wrapper {
  position: relative;
}

.promo-code-settings {
  display: flex
}

@media (max-width: 750px) {
  .promo-settings-button {
    margin: auto;
  }

  .promo-code-settings {
    position: absolute;
    top: 0;
    right: 0;
  }
}