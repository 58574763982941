.dropdown {
  position: absolute;
  top: 4.5rem;
  z-index: 1;
}


.dropdown .MuiSvgIcon-root {
  color: var(--primary-action-color);
}

.dropdown hr {
  border-color: var(--primary-tint-color);
  margin: 0.7rem 0;
}

.dropdown .MuiMenuItem-root {
  border-radius: 8px;
  margin: 0 0.5rem;
  padding: 0 0.5rem;
  color: var(--primary-font-color);
}

.dropdown .MuiMenuItem-root:hover {
  background-color: var(--primary-tint-color);

}

.dropdown.promo-code-settings {
  top: 2.5rem;
  right: 0;
  background-color: var(--primary-background-color);
  border-radius: 15px;
}

.dropdown .MuiMenuItem-root .danger {
  color: #d23e3d;
}

.display-none {
  display: none
}

.dropdown .MuiPaper-root {
  background-color: var(--primary-background-color);
  color: var(--primary-font-color);
  border-radius: 15px;
}