.organization-menu-container {
  background-color: var(--primary-background-color);
  padding: 0;
  right: 0;
  border-radius: 0.75rem;
  box-shadow: 0px 5px 12px var(--primary-background-color);
  top: 0.25rem;
  border: 1px solid var(--secondary-tint-color);
  width: 250px;
}

.organization-menu-button {
  display: flex;
  padding: 0.5rem 1.5rem;
  border-radius: 50px;
  font-weight: bold;
  text-decoration: none;
  user-select: none;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  color: var(--primary-font-color);
  transition: 0.2s opacity ease;
  cursor: pointer;
  width: 100%;
}

.organization-menu-button:hover {
  opacity: 0.6;
}

.studio-dropdown {
  position: absolute;
  top: 3.5rem;
  z-index: 1;
}

.studio-dropdown .MuiPaper-root {
  background-color: var(--input-background-color);
  color: var(--primary-font-color);
  border: 1px solid var(--primary-tint-color);
  border-radius: 0.75rem;
}

.studio-dropdown .MuiSvgIcon-root {
  color: var(--primary-action-color);
}

.studio-dropdown hr {
  border-color: var(--primary-tint-color);
  margin: 0.7rem 0;
}

.studio-dropdown .MuiMenuItem-root {
  border-radius: 8px;
  margin: 0 0.5rem;
  padding: 0 0.5rem;
  min-height: 36px !important;
}

.studio-dropdown .MuiMenuItem-root:hover {
  background-color: var(--primary-tint-color);
}

.studio-nav-title {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  user-select: none;
  transition: 0.2s ease-in-out background-color;
  border-radius: 0.75rem;
  cursor: pointer;
}

.organization-name {
  font-size: 1.15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
}

@media (max-width: 750px) {
  .nav-bar .studio-dropdown {
    top: 2.7rem;
    left: 0.5rem;
    z-index: 10;
  }
}
