.account-balance {
  margin-top: 1rem;
}

.title {
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.description {
  margin-bottom: 0.3rem;
}

.promotion-error {
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgb(189 25 48);
  background: #de8d98;
  border-radius: 5px;
  border: 1px solid rgb(189 25 48);
  padding: 0.5rem;
  font-weight: bold;
}

.created-code {
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.75rem;
  border: none;
  font-size: 2rem;
  margin: 0.5rem 0;
  margin-top: 1rem;
  border: 1px solid var(--secondary-tint-color);
  background-color: var(--input-background-color);
  color: var(--primary-font-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon:hover {
  opacity: 0.6;
  cursor: pointer;
}
