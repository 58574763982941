.insights-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
}

.insight-button {
  margin: 0 1rem;
  font-weight: 600;
  border-radius: 0.75rem;
  padding: 0.5rem;
}

.insight-button:hover {
  cursor: pointer;
  background-color: var(--primary-tint-color);
}

.active {
  background-color: var(--primary-tint-color);
}
