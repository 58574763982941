.save-publish-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 4rem 0 2rem 0;
}

.save-publish-button-container {
  display: flex;
  justify-content: space-evenly;
  width: 25vw;
}

.exercise-draft-container {
  padding-left: 1.5rem;
}

.new-exercise-button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.new-exercise-save-container {
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.program-label {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.secondary-program-label {
  margin-top: 1rem;
}

.program-draft-item-container {
  background-color: var(--primary-background-color);
  border-radius: 0.75rem;
  padding: 0.75rem;
  font-size: 1rem;
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-between;
}

.program-draft-item-container:hover {
  opacity: 0.6;
  border: 1px solid var(--secondary-tint-color);
  cursor: pointer;
}

.program-draft-item-parentTitle {
  opacity: 0.5;
}

.program-draft-item-title {
  font-weight: bold;
}

.program-draft-item-url {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.75rem;
}

.program-draft-item-url a {
  color: var(--primary-font-color);
}

.add-item {
  margin-top: 0.75rem;
}

.program-draft-item-edit-button-container {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 0.75rem 0;
}

.program-draft-item-save-button-container {
  display: flex;
}

.save {
  margin-left: 0.5rem;
}

.program-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.program-error {
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgb(189 25 48);
  background: #de8d98;
  border-radius: 5px;
  border: 1px solid rgb(189 25 48);
  padding: 0.5rem;
  font-weight: bold;
}

.exercise-input-container {
  padding-top: 1rem;
}

.program-draft-title {
  font-weight: bold;
  font-size: 24px;
  margin: 1rem;
}

.program-duplication-container {
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.program-duplication-container .MuiSvgIcon-root {
  margin: 0 0.5rem 0 0.25rem !important;
  font-size: 1.25rem !important;
  padding-bottom: 0.25rem !important;
  color: var(--primary-font-color) !important;
}

.program-duplication-container .MuiSvgIcon-root:hover {
  cursor: pointer;
  opacity: 0.6;
}

.program-duplication-helper-container {
  position: relative;
}

.program-duplication-helper-description {
  display: none;
}

.program-duplication-helper-container:hover
  .program-duplication-helper-description {
  display: block;
  position: absolute;
  z-index: 100;
  top: 25px;
  left: -150px;
  width: 300px;
  border-radius: 15px;
  padding: 0.5rem;
  border: 1px solid var(--secondary-tint-color);
  background-color: var(--container-background-color);
  font-weight: 400;
  font-size: 1rem;
}

.program-preview-draft-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preview-label {
  margin-top: 0.5rem;
}

.draft-button {
  margin-bottom: 0.5rem;
}

.course-wrapper {
  background-color: var(--container-background-color);
  max-width: 1500px;
  margin: auto;
  margin-top: 2rem;
  border-radius: 0.75rem;
  padding: 1rem;
}

.course-meta-buttons {
  display: flex;
  margin-left: 5px;
}

.section-button {
  margin-right: 0.5rem;
  max-width: 140px;
  max-height: 30px;
  font-size: 14px !important;
  padding: 0.5rem !important;
}

@media (max-width: 750px) {
  .course-meta-buttons {
    flex-direction: column;
  }

  .save-publish-button-container {
    width: 100%;
  }

  .new-exercise-save-container {
    margin-left: 1rem;
    width: 55%;
  }

  .section-button {
    margin-top: 0.5rem;
  }
}
