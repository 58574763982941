p {
  margin-top: 1rem;
}

.modal-code {
  font-weight: bold;
}

.promo-code-status-modal {
  max-width: 500px;
  width: 100%;
}