.signout-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: auto;
  background-color: var(--container-background-color);
}

.avatar-sign-out-wrapper {
  position: relative
}

.signout-dropdown {
  position: absolute;
  top: 2.5rem;
  right: 0rem;
  z-index: 1;
  width: 200px;
  padding: 1rem;
  background-color: var(--container-background-color);
  border-radius: 15px;
}

.avatar:hover {
  cursor: pointer;
}