.course-program-update-type {
  font-weight: 600;
}

.course-program-update-type:hover {
  cursor: pointer;
  opacity: 0.6;
}

.activeProgramType {
  border-bottom: var(--primary-action-color) 2px solid;
}

.programs-option-container {
  display: flex;
  width: 100%;
}

select {
  border-radius: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem;
  background-color: var(--input-background-color);
  color: var(--primary-font-color);
  user-select: none;
  border: 1px solid var(--secondary-tint-color);
  margin-top: 1rem;
}

.programs-option-container:hover {
  cursor: pointer;
}
