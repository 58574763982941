.mobile-navigation {
  display: none;
}

@media (max-width: 750px) {
  .mobile-navigation {
    border-top: 1px solid var(--secondary-tint-color);
    color: var(--primary-font-color);
    font-size: 1.2rem;
    justify-content: space-between;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--subnav-background-color);
    box-shadow: 1px 0 5px rgb(0 0 0 / 10%);
    z-index: 10;
    backdrop-filter: blur(10px);
    padding: 0.5rem;
  }

  .mobile-nav-items {
    flex-direction: row;
    text-align: center;
    display: flex;
    font-size: 1.35rem;
    align-items: center;
    flex: 1;
  }

  .mobile-nav-items a {
    flex-direction: column;
    font-size: 0.85rem;
    padding: 0.25rem;
    opacity: 0.5;
    transition: opacity 0.12s ease-in-out;
    padding: 0.5rem 0.75rem;
    display: block;
    text-align: left;
    width: 100%;
    color: var(--primary-font-color);
    text-decoration: none;
    user-select: none;
    background-color: transparent;
    transition: background-color 0.12s ease-in-out, opacity 0.12s ease-in-out;
    border-radius: 0.75rem;
    display: flex;
    align-items: center;
  }

  .mobile-nav-items a.active {
    background-color: transparent;
    opacity: 1;
  }

  .nav-menu-items a svg {
    width: 1.75rem;
    height: 1.75rem;
  }

  .mobile-nav-avatar {
    margin-right: 0;
    height: 1.75rem;
    width: 1.75rem;
    min-width: 1.75rem;
    min-height: 1.75rem;
    font-size: 0.9rem;
    border-radius: 50%;
    background-color: var(--avatar-background-color);
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .mobile-nav-profile-name {
    font-size: 0.85rem;
  }

  .mobile-nav-profile-container.mobile {
    display: block;
  }

  .mobile-nav-items a:hover,
  .mobile-nav-profile-container a:hover {
    background-color: transparent;
  }
}
