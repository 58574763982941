.publish-content {
  margin-top: 1rem;
}

.publish-title {
  font-weight: bold;
  margin: 0.5rem 0 0.75rem 0;
}

.publish-message {
  margin-left: 0.75rem;
}

.publish-footnote {
  font-size: small;
  font-style: italic;
  padding-top: 0.5rem;
}