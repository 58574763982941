.type-container {
  display: flex;
  margin-top: 1rem;
  border: 1px solid var(--secondary-tint-color);
  border-radius: 0.75rem;
  padding: 10px;
}

.type-container:hover {
  opacity: 0.6;
  cursor: pointer;
  border: 1px solid var(--primary-action-color);
}

.MuiRadio-root {
  color: var(--primary-action-color) !important;
}
