* {
  font-family: 'Rubik', sans-serif !important;
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  :root {
    --container-background-color: #222834;
    --input-background-color: #14171e;
    --primary-action-color: #2196f3;
    --primary-background-color: #12161e;
    --primary-font-color: #ffffff;
    --primary-tint-color: #222834;
    --secondary-tint-color: rgb(255 255 255 / 15%);
    --skeleton-tint-color: rgb(255 255 255 / 15%);
    --subnav-background-color: rgb(18 22 30 / 50%);
    --avatar-background-color: #FFAE10;
  }
}
@media (prefers-color-scheme: light) {
  :root {
    --container-background-color: #ededed;
    --input-background-color: #ffffff;
    --primary-action-color: #2196f3;
    --primary-background-color: #ffffff;
    --primary-font-color: #232832;
    --primary-tint-color: rgb(57 62 71 / 10%);
    --secondary-tint-color: #ededed;
    --skeleton-tint-color: rgb(0 0 0 / 15%);
    --subnav-background-color: rgb(255 255 255 / 50%);
    --avatar-background-color: #FFAE10;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-background-color);
  color: var(--primary-font-color);
}

body.app-overflow-hidden {
  overflow: hidden;
}