.subscribe-chip {
  display: flex;
  align-items: center;
}

.subscribe-chip .MuiSvgIcon-root {
  margin-left: 1rem;
  margin-bottom: 0.25rem !important;
}

.subscribe-chip .MuiSvgIcon-root:hover {
  cursor: pointer;
  opacity: 0.6;
}

.programs-container {
  max-width: 700px;
  margin: auto;
  margin-top: 2rem;
  padding: 1rem;
}

.program-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.program-card-container {
  background-color: var(--primary-background-color);
  border: 2px solid var(--container-background-color);
  padding: 1rem;
  border-radius: 15px;
  max-width: 48%;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--primary-font-color);
  flex-basis: 100%;
  margin: 0 0.5rem 0.5rem 0;
}

.course-card-container {
  background-color: var(--container-background-color);
  border: 2px solid var(--container-background-color);
  padding: 1rem;
  border-radius: 15px;
  max-width: 48%;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--primary-font-color);
  flex-basis: 100%;
  margin: 0 0.5rem 0.5rem 0;
}

.course-card-container:hover, .program-card-container:hover {
  border: 2px solid var(--primary-action-color);
}

.program-name {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.program-publisher-name {
  font-weight: normal;
  margin-top: 0.15rem;
}

.program-metadata-container {
  display: flex;
  align-items: center;
}

.program-status-tag {
  text-align: right;
  display: flex;
}

.program-metadata {
  max-width: 85%;
}

.logo {
  width: 2rem;
  height: 2rem;
  border-radius: 6px;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1rem;
  background-color: #d23e3e;
}

.chip-container {
  display: flex;
  flex-direction: row-reverse;
}

.chip-container .MuiChip-label {
  overflow: visible !important;
}

.program-card-container .MuiChip-root {
  color: var(--primary-font-color);
  max-width: fit-content;
}

.course-card-container .MuiChip-root {
  color: var(--primary-font-color);
  max-width: fit-content;
}

.program-header-container {
  display: flex;
  justify-content: space-between;
  max-width: 700px;
  margin: 2rem auto auto auto;
  padding: 1rem;
}

.program-button {
  height: 50%;
}

.subscribe-chip {
  display: flex;
  align-items: center;
}

.subscribe-chip .MuiSvgIcon-root {
  margin-left: 1rem;
  margin-top: 0.25rem !important;
}

.subscribe-chip .MuiSvgIcon-root:hover {
  cursor: pointer;
  opacity: 0.6;
}

.program-search {
  max-width: 700px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.program-input {
  width: 92%;
  margin-right: 1.5rem;
}

@media (max-width: 750px) {
  .program-card-container {
    max-width: 100%;
  }

  .course-card-container {
    max-width: 100%;
  }

  .program-input {
    margin-right: 0;
  }

  .program-metadata {
    max-width: 95%;
  }
}

