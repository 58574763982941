.navigation {
  border-bottom: 1px solid var(--secondary-tint-color);
  color: var(--primary-font-color);
  font-size: 1.2rem;
  font-weight: bold;
  justify-content: space-between;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: var(--subnav-background-color);
  box-shadow: 1px 0 5px rgb(0 0 0 / 10%);
  z-index: 10;
  backdrop-filter: blur(10px);
  padding: 0.5rem;
}

.nav-avatar {
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 50%;
  background-color: var(--avatar-background-color);
  text-align: center;
  margin-right: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.navigation .nav-avatar {
  margin: -1rem 0;
}

.navigation .login {
  font-size: 1rem;
  user-select: none;
  color: var(--primary-font-color);
  text-decoration: none;
}

.connectbetter-logo {
  display: flex;
  align-items: center;
  user-select: none;
  color: var(--primary-font-color);
  text-decoration: none;
}

.studio-nav-title .logo {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 6px;
  margin-right: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: var(--primary-tint-color);
  color: var(--primary-font-color);
  font-size: 1.25rem;
  min-width: 2rem;
  min-height: 2rem;
}

.nav-item-container {
  display: flex;
}

.nav-item {
  font-size: 1rem;
  user-select: none;
  color: var(--primary-font-color);
  text-decoration: none;
  margin: 0 0.75rem;
  padding: 0.5rem;
  border-radius: 0.75rem;
}

.nav-item:hover {
  background-color: var(--primary-tint-color);
}

.nav-item.active {
  background-color: var(--primary-tint-color);
  opacity: 1;
}

.tag {
  margin-left: 0.75rem;
  font-size: 14px;
  background-color: var(--container-background-color);
  padding: 0.25rem;
  border-radius: 5px;
}

.mobile-bottom {
  display: none;
}

.nav-log-organization-selection-container {
  display: flex;
}

.sign-out:hover {
  opacity: 0.6;
}

@media (max-width: 750px) {
  .mobile {
    display: none;
  }

  .mobile-bottom {
    display: block;
    position: absolute;
    bottom: 0vh;
  }
}
