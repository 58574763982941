.marketing-wrapper {
  max-width: 1000px;
  margin: auto;
  margin-top: 2rem;
}

.marketing-title-header {
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.promo-credits-container {
  border: 1px solid var(--secondary-tint-color);
  border-radius: 15px;
  padding: 1rem;
  margin-top: 2rem;
}

.created-promo-code-container {
  border: 1px solid var(--secondary-tint-color);
  border-radius: 15px;
  padding: 1rem;
  max-width: 1000px;
  width: 100%;
  margin: 5rem 1rem 1rem 1rem;
}

.promo-codes-header {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
}

.promo-codes-header .title {
  margin-bottom: 0;
}

.promo-code {
  border-top: 1px solid var(--secondary-tint-color);
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.promo-settings-button {
  padding: 0;
  margin-top: 1rem;
  height: 1rem;
}

.promo-list-item {
  margin-top: 1rem;
  flex: 1;
}

.empty-state{
  max-width: 300px;
  width: 100%;
  margin: 1rem auto;
  border-radius: 15px;
  border: 1px solid var(--secondary-tint-color);
  padding: 1rem 0.4rem;
}

.empty-state > div {
  width: 70%;
  margin-top: 1rem;
}

.credit-item {
  margin-top: 0.2rem;
}

.billing-container {
  max-width: 1000px;
  margin: auto;
  margin-top: 2rem;
  padding: 1rem;
}

.promotions-container, .account-credit-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  max-width: 1000px;
  width: 100%;
  border-radius: 15px;
  padding: 1.5rem;
  background-color: var(--container-background-color);
  overflow-x: auto;
}

.promotions-container {
  padding-bottom: 6rem;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.billing-next-attempt-at {
  opacity: 0.6;
  display: flex;
}


@media (max-width: 750px) {
  .marketing-wrapper {
    display: unset;
  }

  .promo-code {
    display: block;
    position: relative;
  }

  .promo-codes-header {
    display: block;
  }

  .created-promo-code-container{
    max-width: 300px;
  }

  .create-promo-code-button {
    margin-top: 1rem;
  }
}
