.exercise-action-menu-dropdown-container {
  position: absolute;
  z-index: 10;
  right: -6rem;
  top: -0.5rem;
}

.exercise-action-menu-dropdown-container .MuiPaper-root {
  background-color: var(--input-background-color);
  color: var(--primary-font-color);
  border: 1px solid var(--primary-tint-color);
  border-radius: 0.75rem;
  width: 400px !important;
}

.exercise-action-menu-dropdown-container .MuiSvgIcon-root {
  color: var(--primary-action-color);
}

.exercise-action-menu-dropdown-container hr {
  border-color: var(--primary-tint-color);
  margin: 0.7rem 0;
}

.exercise-action-menu-dropdown-container .MuiMenuItem-root {
  border-radius: 8px;
  padding: 0 0.5rem;
  width: 100% !important;
}
